import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';
import authPic from '../../assets/img/icon/location-alt.png';
import locPic from '../../assets/img/icon/location-alt.png';
import locPic2 from '../../assets/img/icon/location2.png';
import placeholder from '../../../../../assets/undraw/undraw_apartment.png';


function PropertyGrid() {
	const { location, updatedUnits } = useContext(Context);
	const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
	const address = JSON.parse(location.addresses);
	const [properties, setProperties] = useState(updatedUnits.sort((a, b) => a.node.sqft - b.node.sqft));

	useEffect(() => {
    const vacant = [];
    const notVacant = [];
    properties.forEach((p, i) => {
      if(p.node.status === "vacant") {
        vacant.push(p);
      } else {
        notVacant.push(p)
      }
    })
    setProperties(vacant.concat(notVacant));
  }, [])
	useEffect(() => {
		const $ = window.$;
	
		$( 'body' ).addClass( 'body-bg' );
	},[]);

	const handleLink = (k) => {
		localStorage.setItem("selectedUnit", JSON.stringify(k));
		window.scrollTo(0,0);
	}

	return (
		orgDetails !== undefined &&
		<div className="blog-page-area pt-5 go-top">
			<div className="container">
				<div className="row justify-content-center">
					{properties.map((k,i) => {
						const { node } = k;
						return (
							<div
								key={node.id}
								className={"col-lg-4 col-md-6 " + node.status + ` ${node.status !== "vacant" && "grayout"}`}>
								<div className="single-product-wrap style-2">
									<Link
										to="/property-details"
										onClick={()=> {
											handleLink(k);
										}}
									>
										<div className="thumb">
												<img style={{height: '216.8px', width: '350px'}} src={node.photos[0] ? node.photos[0] : placeholder}alt="img" />
											<div className="product-wrap-details">
												<div className="media">
													<div className="author">
														<img src={authPic} alt="img" />           
													</div>
													<div className="media-body">
														<h6>
															<Link
																to="/property-details"
																onClick={()=> {
																	handleLink(k);
																}}
																>
																Company
															</Link>
														</h6>
														<p><img src={locPic} alt="img" />{orgDetails&&orgDetails.name}</p>
													</div>
												</div>
											</div> 
										</div> 
									</Link>
									<div className="product-details-inner">
										<h4>
											<Link
												to="/property-details"
												onClick={()=> {
													handleLink(k);
												}}
											>
												{node.unitType.type}
											</Link>
										</h4>
										<ul className="meta-inner">
											<li><img src={locPic2} alt="img" />{address[0].city}</li>
											<li>
												<Link
													to="/property-details"
													onClick={()=> {
														handleLink(k);
													}}
												>
													{node.status.slice(0,1).toUpperCase()}{node.status.slice(1, node.status.length)}
												</Link>
											</li>
										</ul>
										<p>{node.description}</p>
										<span className="price">${node.price}</span>
									</div>
									<div className="product-meta-bottom style-2">
										<span>{node.bedrooms} <span>Bedroom</span></span>
										<span className="border-none">{node.bathrooms} <span>Bathroom</span></span>
										<span>{node.sqft} <span>sqft</span></span>
									</div>         
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	);
}

export default PropertyGrid