import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'
import { optionsCountry, optionsMonth, optionsYear, optionsState, PAYMENTS_URL, optionsMonthNumber, optionsYearCard, formatPhoneNumberCard } from '../../utils/constants.js'
import { createApplication, savedInfoPaymentMutationGql } from '../../store/schemas/location.jsx'
import { getClient } from "../../init-apollo-googleFn";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";


const paymentsClient = getClient(PAYMENTS_URL);
function Fees(props) {
  const [feesData, setFeesData] = useState({
    zip: null,
    street: null,
    state: null,
    city: null,
    country: null,
    cardHolderName: null,
    cardHolderNumber: null,
    cardHolderExpMonth: null,
    cardHolderExpYear: null,
    cardHolderCVN: null
  });
  const [loader, setLoader] = useState(false);


  const handleChange = e => {
    const { name, value } = e.target;
    setFeesData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeSelect = (name, value) => {
    console.log(name, value)
    setFeesData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const applicationPay = () => {
    setLoader(true)
    paymentsClient
      .mutate({
        mutation: savedInfoPaymentMutationGql,
        variables: {
          paymentMethod:
          {
            type: "manual_credit_card",
            backgroundCheck: false,
            applicationFee: true,
            applicationFeeToLeasera: true,
            creditCard: {
              zipPostalCode: feesData.zip,
              streetAddress: feesData.street,
              state: feesData.state,
              city: feesData.city,
              country: feesData.country,
              cardHolderName: feesData.cardHolderName,
              cardHolderNumber: feesData.cardHolderNumber.replace(/[^0-9.]+/g, ''),
              cardHolderExpMonth: feesData.cardHolderExpMonth,
              cardHolderExpYear: feesData.cardHolderExpYear,
              cardHolderCVN: feesData.cardHolderCVN.replace(/[^0-9.]+/g, '')
            },
            customer: {
              firstName: props.dataObject.Page2.firstName,
              lastName: props.dataObject.Page2.lastName,
              dateOfBirth: moment(props.dataObject.Page5.DOB).format("YYYY-MM-DD"),
              email: props.dataObject.Page2.email,
              phoneMobile: props.dataObject.Page2.phoneMobile.replace(/[^0-9.]+/g, ''),
            }
          }
        },
      })
      .then((results) => {
        setLoader(false)
        props.setAppStep(10)
        props.setDataObject(prevState => ({ ...prevState, Page9: true }))
        props.notifyTrue()
      })
      .catch((e) => {
       props.notifyFalse()
        setLoader(false)
      });
  };

  const disabledCheck = () => {
    if (loader) {
      return true
    }
    if (!feesData.cardHolderName || !feesData.street || !feesData.city || !feesData.country ||!feesData.zip ||!feesData.cardHolderName ||!feesData.cardHolderNumber || !feesData.cardHolderExpMonth ||!feesData.cardHolderExpYear ||!feesData.cardHolderCVN) {
        return true
    }
    if (feesData.cardHolderName.trim().length === 0 || feesData.street.trim().length === 0 || feesData.city.trim().length === 0 || feesData.country.trim().length === 0||
    feesData.zip.trim().length === 0 || feesData.cardHolderName.trim().length === 0 || feesData.cardHolderNumber.trim().length === 0 || feesData.cardHolderExpMonth.trim().length === 0 ||
    feesData.cardHolderExpYear.trim().length === 0 || feesData.cardHolderCVN.trim().length === 0) {
        return true
    }
    else return false
  }

  function addHyphen (element) {
    if(element) {
      
      element.split('-').join('');    // Remove dash (-) if mistakenly entered.
  
      let finalVal = element.match(/.{1,4}/g).join('-');
      return finalVal
    }
  }


  return (
    <>
      <div>
        <h1 class="section__heading">Pay fees</h1>
        <p>The following fees are necessary for us to verify your rental application.</p>
        <p><bold>Application fee:</bold> $49.95</p>
        <Form>
          <Form.Field required>
            <label>Name</label>
            <input name="cardHolderName" value={feesData && feesData.cardHolderName} placeholder='Name' onChange={handleChange} />
          </Form.Field>
          <Form.Field required>
            <label>Address</label>
            <input d value={feesData && feesData.street} name="street" placeholder='Address' onChange={handleChange} />
          </Form.Field>
          <Form.Field required>
            <label>City</label>
            <input value={feesData && feesData.city} name="city" placeholder='City' onChange={handleChange} />
          </Form.Field>
          <Form.Select
            fluid
            search
            required
            label='State'
            name="state"
            options={optionsState}
            value={feesData && feesData.state}
            placeholder="State"
            onChange={(e, { value }) => handleChangeSelect("state", value)}
          />
          <Form.Field required>
            <label>Zip</label>
            <input   onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} value={feesData && feesData.zip} name="zip" placeholder='Zip' onChange={handleChange} />
          </Form.Field>
          <Form.Select
            fluid
            required
            search
            label='Country'
            name="country"
            options={optionsCountry}
            value={feesData && feesData.country}
            placeholder="Country"
            onChange={(e, { value }) => handleChangeSelect("country", value)}
          />
          <Form.Field required>
            <label>Card Number</label>
            <input onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} maxLength="16" value={feesData && formatPhoneNumberCard(feesData.cardHolderNumber)} name="cardHolderNumber" placeholder='Number' onChange={handleChange} />
          </Form.Field>
          <Form.Group fluid widths="equal">
            <Form.Select
              fluid
              required
              search
              label='Expiration Month'
              name="cardHolderExpMonth"
              options={optionsMonthNumber}
              value={feesData && feesData.cardHolderExpMonth}
              placeholder="Month"
              onChange={(e, { value }) => handleChangeSelect("cardHolderExpMonth", value)}
            />
            <Form.Select
              fluid
              required
              label='Expiration Year'
              name="cardHolderExpYear"
              options={optionsYearCard}
              value={feesData && feesData.cardHolderExpYear}
              placeholder="Year"
              onChange={(e, { value }) => handleChangeSelect("cardHolderExpYear", value)}
            />
            <Form.Field required>
              <label>Security Code</label>
              <input value={feesData && feesData.cardHolderCVN} name="cardHolderCVN" placeholder='Code' onChange={handleChange} />
            </Form.Field>
          </Form.Group>


        </Form>
        <br></br>
        <div style={{ float: 'right' }}>
          <Button icon labelPosition='left' onClick={() => props.setAppStep(9)}>
            <Icon name='left arrow' />
            Previous
          </Button>
          <Button loading={loader} disabled={disabledCheck()} icon labelPosition='right' onClick={() => applicationPay()}>
            Submit and Continue
            <Icon name='right arrow' />
          </Button>
        </div>
          <p>You will have an opportunity to confirm application details on the next page.</p>

      </div>
    </>
  );
}

export default Fees 