import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../AppContext';
import bannerVid from '../../../../assets/banner/header-vid.webm';

function BannerV4 ()  {
  const { location, photosArray } = useContext(Context);

  const inlineStyle = {
    backgroundImage: `url(${photosArray[0]})`
  }

  // TODO marginBottom under video src need an if statement checking if a video link has been queried, else don't show and instead use photo for background.

  return (
    <>
        {/* <video autoPlay loop muted id='video' style={{position: 'absolute', width: '100vw'}}>
          <source src={bannerVid} type='video/mp4'/>
        </video> */}
      {/* Add to div below when video showing style={{height: '833.05px'}} */}
      <div className="banner-area jarallax" style={inlineStyle}>
        <div className="container">
          <div className="banner-inner-wrap">
            <div className="row">
              <div className="col-lg-10">
                <div className="banner-inner">
                  <h1 className="title welcome">Welcome to {location.customId}</h1>
                    <Link 
                      className='appointment'
                      to="/contact" 
                      onClick={()=> {
                        window.scrollTo(0,0);
                      }}  
                    >
                      GET APPOINTMENT
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerV4