import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'
import { optionsHow, formatPhoneNumber } from '../../utils/constants.js'


function ContactUs(props) {
    const [disableMiddle, setDisableMiddle] = useState(false);
    const [confirmEmail, setConfirmEmail] = useState('');
    const [ContactUs, setContactUs] = useState({

    });

    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    useEffect(() => {
        const itemToSave = JSON.stringify(ContactUs)
        if (Object.keys(ContactUs).length !== 0) {
            console.log(localStorage)
            localStorage.setItem('ContactUs', itemToSave)
        }
    }, [ContactUs]);
    useEffect(() => {
        localStorage.setItem('confirmEmail', confirmEmail)
    }, [confirmEmail]);

    useEffect(() => {
        if (performance.navigation.type === 1) {
            const savedData = localStorage.getItem('ContactUs')
            const confirmEmail = localStorage.getItem('confirmEmail')
            if (savedData) setConfirmEmail(confirmEmail)
            if (savedData) setContactUs(JSON.parse(savedData))
        } else {
            const savedData = localStorage.getItem('ContactUs')
            const savedEmail = JSON.parse(savedData)
            if (savedEmail&&savedEmail.email) setConfirmEmail(savedEmail.email)
            if (savedData) setContactUs(JSON.parse(savedData))
        }
    }, []);



    useEffect(() => {
        if (disableMiddle == true) {
            setContactUs(prevState => ({
                ...prevState,
                ["middleName"]: ''
            }));

        }
    }, [disableMiddle]);

    const handleChange = e => {
        const { name, value } = e.target;
        setContactUs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChangeSelect = (name, value) => {
        // const { name, value } = e.target;
        console.log(name, value)
        setContactUs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const Stepper = () => {
        props.setDataObject(prevState => ({ ...prevState, Page2: ContactUs }))
        // props.setDataObject(ContactUs)
        props.setAppStep(3)
    }



    const disabledCheck = () => {
        if (ContactUs.email !== confirmEmail) {
            return true
        }
        if (regexExp.test(ContactUs.email) !== false) {
            return true
        }
        if (!ContactUs.firstName || !ContactUs.lastName || !ContactUs.email || !ContactUs.phoneMobile) {
            return true
        }
        if (ContactUs.firstName.trim().length === 0 || ContactUs.lastName.trim().length === 0 || ContactUs.email.trim().length === 0 || ContactUs.phoneMobile.trim().length === 0) {
            return true
        }
        else return false
    }
    const TestEmail = () => {
        if (regexExp.test(ContactUs.email)) {
            return true
        }
        else return false
    }
    return (
        <>
            <div>
                <h1 class="section__heading">Your contact info</h1>
                <p>Please let us know how we can best reach you.</p>

                <Form>
                    <Form.Field required>
                        <label>First Name</label>
                        <input name="firstName" value={ContactUs.firstName} placeholder='First Name' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>Last Name</label>
                        <input name="lastName" value={ContactUs.lastName} placeholder='Last Name' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Middle Name</label>
                        <input disabled={disableMiddle} value={ContactUs.middleName} name="middleName" placeholder='Middle Name' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox name="noMiddle" label='I certify I have no middle name' checked={disableMiddle} onChange={() => setDisableMiddle(!disableMiddle ? true : false)} />
                    </Form.Field>
                    <Form.Field>
                        <label>Suffixes</label>
                        <input name="title" placeholder='Suffixes' value={ContactUs.title} onChange={handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>Email Address </label>
                        <input name="email" placeholder='Email' value={ContactUs.email} onChange={handleChange} />
                        {
                            TestEmail() ?
                                "" : <span style={{ color: 'red' }}>* Must be valid Email</span>
                        }
                    </Form.Field>
                    <Form.Field required>
                        <label>Confirm Email Address</label>
                        <input placeholder='Confirm Email' value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                    </Form.Field>
                    <Form.Group widths="equal">
                        <Form.Field fluid required>
                            <label>Phone Number</label>
                            <input maxlength="10" name="phoneMobile" placeholder='Phone' value={formatPhoneNumber(ContactUs.phoneMobile)} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} onChange={handleChange} />
                        </Form.Field>
                        {/* <Form.Select
                    fluid
                    label='Type'
                    name="type"
                    options={options}
                    value={props.dataObject.phoneType}
                    placeholder="Phone Type"
                    onChange={(e,{value}) => handleChangeSelect("phoneType",value)}
                /> */}
                    </Form.Group>
                    <Form.Field required>
                        <Form.Select
                            fluid
                            label='How did you hear about us?'
                            name="source"
                            options={optionsHow}
                            value={ContactUs.source}
                            placeholder="How?"
                            onChange={(e, { value }) => handleChangeSelect("source", value)}
                        />
                    </Form.Field>
                </Form>
                <br></br>
                <div style={{ float: 'right' }}>
                    <Button icon labelPosition='left' onClick={() => props.setAppStep(1)}>
                        <Icon name='left arrow' />
                        Previous
                    </Button>
                    <Button icon labelPosition='right' disabled={disabledCheck()} onClick={() => Stepper()}>
                        Save & Next
                        <Icon name='right arrow' />
                    </Button>
                </div>

            </div>
        </>
    );
}

export default ContactUs