import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Segment, Header, Button } from 'semantic-ui-react'
import moment from "moment";
import { Link } from 'react-router-dom';
import './Application.css'
import "react-day-picker/lib/style.css";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

function Begin(props) {
  const [selected, setSelected] = useState(props.dataObject.Page1 ? props.dataObject.Page1 : "");


  useEffect(() => {
    console.log(selected)
    localStorage.setItem('selected', selected)
  }, [selected]);

  useEffect(() => {
    if (performance.navigation.type === 1) {
      const selecteda = localStorage.getItem('selected')
      if (selecteda) setSelected(selecteda)
    } else {
      const selecteda = localStorage.getItem('selected')
      if (selecteda) setSelected(selecteda)
    }
  }, []);

  const handleChange = e => {
    props.setDataObject(prevState => ({
      ...prevState,
      ["targetMoveInDate"]: moment(e).format('MM/DD/YYYY')
    }));
  };

  const Stepper = () => {
    props.setDataObject(prevState => ({ ...prevState, Page1: selected }))
    // props.setDataObject(selected)
    props.setAppStep(2)
  }

  return (
    <>
      <div>
        {
          props.doc&&props.doc.applicationDocPath &&
          <embed type="video/webm"
            src={`${props.doc.applicationDocPath}#toolbar=0&navpanes=0&scrollbar=0`}
            width="100%"
            height="1000px" />
        }

      </div>

      <Segment className="application-start-segment">
        <Header>You are applying to rent:
          <Header.Subheader>
          </Header.Subheader>
          <br></br>
          <Header.Subheader>
            Desired Move-In Date
          </Header.Subheader>
          <div style={{ width: '200px' }}>
            <Flatpickr value={selected & selected} onChange={([date]) => {
              setSelected(moment(date).format("MM/DD/YYYY"));
            }} />

          </div>

        </Header>
        <Button disabled={!selected} onClick={() => Stepper()}>Get Started</Button>
      </Segment>
    </>
  );
}

export default Begin