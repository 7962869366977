import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../../AppContext';
import icon7 from '../../assets/img/icons/7.png';
import { Link } from "react-router-dom";
import placeholder from '../../../../assets/undraw/undraw_apartment.png';

function 
Property() {
  const { updatedUnits } = useContext(Context);
  const [activeFilter, setActiveFilter] = useState("all");
  const [properties, setProperties] = useState(updatedUnits.sort((a, b) => a.node.sqft - b.node.sqft));

  /* eslint-disable */
  useEffect(() => {
    const vacant = [];
    const notVacant = [];
    properties.forEach((p, i) => {
      if(p.node.status === "vacant") {
        vacant.push(p);
      } else {
        notVacant.push(p)
      }
    })
    setProperties(vacant.concat(notVacant));
  }, [])
  /* eslint-enable */

  const handleClick = (filter) => {
    setActiveFilter(filter);
  };

  const addDefaultSrc = (e) => {
    e.target.src = placeholder;
  }

  const noPhoto = ['201', '411', '208', '105', '206', '204', '207', '110', '211', '302', '509', '105', '106'];

  return (
    <div className="prop-container pd-top-60">
      <div className="container">
        <div className="row custom-gutter">
          <div className="col-lg-12">
            <div className="property-filter-menu-wrap">
              <div className="property-filter-menu portfolio-filter text-center">
                <button 
                  data-filter="*" 
                  className={activeFilter === "all" ? "active" : ""}
                  onClick={() => handleClick("all")}
                >
                  All Units
                </button>
                <button 
                  data-filter=".vacant"
                >
                  Vacant Units
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Property filter Start*/}
        <div className="property-filter-area row custom-gutter">
          <div className="gallery-sizer col-1" />
          {/* property item Start*/}
          { properties.map((p, i) => {
            const { node } = p;
            return(
              !noPhoto.includes(node.number) &&
              <div key={i} className={"prop-container-body rld-filter-item  col-lg-3 col-sm-6 "+ node.status + ` ${node.status !== "vacant" && "grayout"}`}>
                <div className="single-feature">
                  <div className="thumb">
                      <img 
                        height="201.5"
                        width="310px"
                        onError={(e)=>addDefaultSrc(e)}
                        src={node.photos[2] !== undefined ? node.photos[2] : (node.photos[1] !== undefined) ? node.photos[1] : (node.photos[0] !== undefined) ? node.photos[0] : placeholder} 
                        alt="img" 
                      />
                  </div>
                  <div className="details prop-container-details">
                    <h6 className="author template-p"><i className="fa fa-building" />Floor: {node.level}</h6>
                    <h6 className="prop-container-card-title title readeal-top">Unit: {node.number}</h6>
                    {node.status !== null &&
                      <>
                        <h6 className="price">Status: {node.status.slice(0, 1).toUpperCase() + node.status.slice(1).toLowerCase()}</h6>
                        <br/>
                      </>
                    }
                    <h6 className="price">${node.price}</h6>
                    <ul className="info-list">
                      <li><i class="fa fa-bed"></i>{node.bedrooms}</li>
                      <li><i class="fa fa-bath"></i>{node.bathrooms}</li>
                      <li><img src={ icon7 } alt="sqft icon" /> {node.sqft}</li>
                    </ul>
                    <ul className="contact-list">
                      <li className="readeal-top">
                        <Link
                          style={{backgroundColor: '#fda94f', color: 'white'}}
                          className={`btn btn-yellow ${node.status !== "vacant" && "disabled-link"}`}
                          to="unit-details"
                          onClick={()=> {
                            localStorage.setItem("selectedUnit", JSON.stringify(p));
                          }} 
                        >
                          View Details
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
              
          )}
        </div>
      </div>
    </div>
  )
}

export default Property